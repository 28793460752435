export const menuItems = [
    {
        id: 0,
        name: 'main',
        to: 'top'
    },
    {
      id: 1,
      name: 'about',
      to: 'about'
    },
    {
        id: 2,
        name: 'services',
        to: 'services'
    },
    {
        id: 3,
        name: 'barbers',
        to: 'barbers'
    },
    {
        id: 4,
        name: 'contacts',
        to: 'contacts'
    }
]